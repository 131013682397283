<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    <b-button variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute">Сохранить</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                no-border-collapse
                sticky-header="true"
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-info">8</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItemWithAttachedFiles(`Удалить ${selectAll ? 'все' : 'выбранные'} записи?`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(rent_norm)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.rent_norm"
                                  @change="v => data.item.rent_norm = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'rent_norm', data.item.rent_norm, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(daily_avg)="data">
                    <b-form-input v-if="variantAttribute"
                        :value="data.item.daily_avg"
                        @change="v => data.item.daily_avg = v"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^[0-9]+$')"
                        @blur="inputFixedVldtn(data.item, 'daily_avg', data.item.daily_avg, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(rent_avg)="data">
                    <b-form-input v-if="variantAttribute"
                        :value="data.item.rent_avg"
                        @change="v => data.item.rent_avg = v"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^[0-9]+$')"
                        @blur="inputFixedVldtn(data.item, 'rent_avg', data.item.rent_avg, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(people_num)="data">
                    <b-form-input v-if="variantAttribute"
                        :value="data.item.people_num"
                        @change="v => data.item.people_num = v"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^[0-9]+$')"
                        @blur="inputFixedVldtn(data.item, 'people_num', data.item.people_num, 0)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(cost_avg)="data">
                    <b-form-input v-if="variantAttribute"
                        :value="data.item.cost_avg"
                        @change="v => data.item.cost_avg = v"
                        @keyup.enter.exact="keyup13"
                        @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                        @blur="inputFixedVldtn(data.item, 'cost_avg', data.item.cost_avg, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell()="data">
                    <div>{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="openModalRowFilesByRowId(data.item.id, false)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <b-dropdown v-if="variantAttribute" id="dropdown-dropleft" dropleft class="more">
                        <template v-slot:button-content>
                            <i class="icon icon-more"></i>
                        </template>
                        <template>
                            <b-dropdown-item @click="openModalRowFilesByRowId(data.item.id, true)">
                                Добавить файл(ы)
                            </b-dropdown-item>
                            <b-dropdown-item v-if="variantAttribute" @click="deleteItemWithAttachedFiles('Удалить запись?', data.item, data.index)">
                                Удалить
                            </b-dropdown-item>
                        </template>
                    </b-dropdown>
                </template>
                <template #bottom-row="data">
                    <td class="text-right" colspan="7">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
            <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      ref="fileUpdown"
        ></files-updown>
        <modal-attach ref="modalAttach"
                      :row-files-input="row_files"
                      :header="header"
                      :is-add="isAdd"
                      :variant-attribute="variantAttribute"
                      @toggleIsAdd="toggleIsAdd($event)"
                      @fileUpload="fileUpload"/>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import { Ax } from '@/utils';
// import BudgetAttachFile from "./components/budget-attach-file";
import ModalAttach from '@/modules/budget-request/components/modal-attach.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_161',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, ModalAttach, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '01-161',
                name_ru: 'Расчет расходов на служебные командировки внутри страны',
                name_kk: 'Ел iшiндегi қызметтiк iссапарларға арналған шығыстарды есептеу'
            },
            tableFields: [
                {
                    key: 'action',
                    label: ' '
                },
                // {
                //     key: 'post_group',
                //     label: 'Наименование должностей'
                // },
                // {
                //     key: 'local_category',
                //     label: 'КНП'
                // },
                // {
                //     key: 'kato',
                //     label: 'Наименование населенного пункта'
                // },
                {
                    key: 'cost_norm',
                    label: 'Норма возмещения суточных расходов на 1 чел. (2 х МРП) (тенге)'
                },
                {
                    key: 'rent_norm',
                    label: 'Норма расходов по найму жилого помещения в сутки на 1 чел. (тенге)'
                },
                {
                    key: 'daily_avg',
                    label: 'Среднегодовое количество человеко/дней для расчета суточных расходов (чел/дн)'
                },
                {
                    key: 'rent_avg',
                    label: 'Среднегодовое количество человеко/дней для расчета расхода по найму жилого помещения (чел/дн)'
                },
                {
                    key: 'people_num',
                    label: 'Среднегодовое количество командируемых человек (чел)'
                },
                {
                    key: 'cost_avg',
                    label: 'Средняя стоимость одного проезда в оба конца (тенге)'
                },
                {
                    key: 'total',
                    label: 'Сумма расходов (тыс.тенге) (гр.4 х гр.6+гр.5 х гр.7+гр.8 х гр.9)/1000'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dictPost: [],
            dictLocal: [],
            dictObl: [],
            dictKato: [],
            dictLLP: null,
            mrp: 0,
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadDictLLP();
        await this.loadDictPost();
        await this.loadDictLocal();
        await this.loadDictObl();
    },
    methods: {
        openModalRowFilesByRowId(rowId, isAdd) {
            this.row_files = this.budgetForm.find(b => b.id === rowId)['row_files'];
            this.$refs.modalAttach.openModalRowAddFilesByRowId(rowId);
            this.rowId = rowId;
            this.isAdd = isAdd;
        },
        toggleIsAdd(return_object) {
            const curBudgetForm = this.budgetForm.find(b => b.id === this.rowId)
            curBudgetForm['row_files'] = return_object['row_files']
            curBudgetForm['files'] = return_object['num_attach_files']
            this.isAdd = return_object['isAdd'];
        },
        fileUpload(rowId) {
            this.$refs.fileUpdown.openModalFileUpload();
            this.$refs.fileUpdown.setRowId(rowId);
        },
        getNewFiles(files) {
            this.budgetForm.forEach(item => {
                let num_attach_files = 0;
                item['row_files'] = [...item['row_files'], ...files];
                item['row_files'].forEach((file) => {
                    if (file.row_id === item['id']) {
                        num_attach_files += 1;
                    }
                });
                item['files'] = num_attach_files;
            });
            this.$refs.modalAttach.addNewFiles(files);
        },
        delFile(fileId) {
            this.budgetForm.forEach(item => {
                item['row_files'].forEach((file, index) => {
                    if (file.file_id === fileId) {
                        item['row_files'].splice(index, 1);
                    }
                });
                item.files = item['row_files'].filter(i => i.row_id !== null).length;
            });
            this.$refs.modalAttach.delFile(fileId);
        },
        addItem() {
            const that = this;
            const item = { id: this.newRowStartId };
            this.newRowStartId--;
            this.itemUpdate(item);

            Object.defineProperty(item, 'total', {
                get: function () {
                    const total = (that.number(item.cost_norm) * that.number(item.daily_avg)
                        + that.number(item.rent_norm) * that.number(item.rent_avg)
                        + that.number(item.people_num) * that.number(item.cost_avg)) / 1000;
                    return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                }
            });
            this.budgetForm.push(item);
        },

        async loadSpecificData() {
            await this.loadDictNormativeInds();
        },

        defineLimit(item) {
            let limit = this.dictLLP.filter(function (row) {
                if ((row.code_local === item.local_category.code)
                    && (row.code_post === item.post_group.code)
                    && (row.exception === item.kato.code)) { return row; }
            });

            if (limit.length === 0) {
                limit = this.dictLLP.filter(function (row) {
                    if ((row.code_local === item.local_category.code)
                        && (row.code_post === item.post_group.code)
                        && (row.exception === null)) { return row; }
                });

                if (limit.length > 0) {
                    item.limit = parseInt(limit[0].limit);
                }
            } else {
                item.limit = parseInt(limit[0].limit);
            }
        },

        downloadRep() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/1/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code.replace('-', '_') + '.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        downloadRepR() {
            Ax(
                {
                    url: '/api-py/budg_' + this.form.code.replace('-', '_') + '/0/' + JSON.stringify(this.header),
                    method: 'POST',
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Форма ' + this.form.code.replace('-', '_') + '(расшифровка).xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.makeToast('danger', 'Ошибка запроса downloadRep()', error.toString());
                }
            );
        },

        async itemUpdate(item) {
            this.$set(item, 'post_group', '');
            this.$set(item, 'local_category', '');
            this.$set(item, 'obl', null);
            this.$set(item, 'dict_kato', []);
            this.$set(item, 'kato', '');
            this.$set(item, 'limit', null);
            this.$set(item, 'cost_norm', this.mrp * 2);
            this.$set(item, 'rent_norm', 0);
            this.$set(item, 'daily_avg', 0);
            this.$set(item, 'rent_avg', 0);
            this.$set(item, 'people_num', 0);
            this.$set(item, 'cost_avg', 0);
            this.$set(item, 'itemToDelete', false);

            this.$set(item, 'files', 0);

            try {
                this.load = true;
                const response = await fetch(`/api-py/get-new-row-files-form/${item.id}/` + JSON.stringify(this.header));
                const emptyRowFiles = await response.json();
                this.$set(item, 'row_files', emptyRowFiles);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса itemUpdate', error.toString());
            }
            this.load = false;
        },

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            const item = list.filter(function (row) {
                if (row.value.code === code) { return row; }
            });

            if (item.length > 0) {
                return item[0].value;
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            this.load = true;
            await this.budgetForm.splice(0);
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(this.header));
                values = await response.json();

                await values.forEach(val => {
                    const item = {
                        id: val.id,
                        post_group: val.post_group === '' ? '' : this.getItem(val.post_group, this.dictPost),
                        local_category: val.local_category === '' ? '' : this.getItem(val.local_category, this.dictLocal),
                        dict_kato: [],
                        cost_norm: this.mrp * 2,
                        rent_norm: val.rent_norm,
                        daily_avg: val.daily_avg,
                        rent_avg: val.rent_avg,
                        people_num: val.people_num,
                        files: val.files,
                        row_files: val.row_files,
                        itemToDelete: false,
                        cost_avg: val.cost_avg
                    };
                    if (val.kato !== '') {
                        this.loadDictKato(item, val.local_category, parseInt(val.kato.substr(0, 2)), val.kato);
                    }

                    Object.defineProperty(item, 'total', {
                        get: function () {
                            const total = (that.number(item.cost_norm) * that.number(item.daily_avg)
                                + that.number(item.rent_norm) * that.number(item.rent_avg)
                                + that.number(item.people_num) * that.number(item.cost_avg)) / 1000;
                            return parseFloat((Math.round(total * 100) / 100).toFixed(2));
                        }
                    });

                    this.budgetForm.push(item);
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas', error.toString());
                return;
            }
            this.load = false;
        },

        async loadDictKato(item, local, ab = 0, kato = '000000000') {
            item.dict_kato.splice(0);
            try {
                const response = await fetch('/api-py/dict_kato/' + local + '/?ab=' + ab + '&kato=' + kato);
                const items = await response.json();

                for (const row of items) {
                    if (row.code === kato) {
                        this.$set(item, 'kato', row);
                        this.defineLimit(item);
                    }
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    item.dict_kato.push(el);
                }

                if (item.local_category.code === '05') {
                    for (const obl of this.dictObl) {
                        if (obl.value.ab === parseInt(kato.substr(0, 2))) {
                            item.obl = obl.value;
                        }
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictKato', error.toString());
            }
        },

        async loadDictLocal() {
            try {
                const response = await fetch('/api-py/dictionary/local_categories/');
                const items = await response.json();

                for (const row of items) {
                    if (row.code !== '06') {
                        const el = {};
                        this.$set(el, 'value', row);
                        this.$set(el, 'text', row.name_ru);
                        this.dictLocal.push(el);
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictLocal', error.toString());
            }
        },

        async loadDictLLP() {
            try {
                const response = await fetch('/api-py/dictionary/local_post_limit/');
                this.dictLLP = await response.json();
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictLLP', error.toString());
            }
        },

        async loadDictNormativeInds() {
            try {
                const response = await fetch(`/api-py/dictionary-normative-inds/${this.header.year}/${this.header.cur_year}/${this.header.variant_date_time}`);
                const items = await response.json();

                for (const row of items) {
                    if (row.code === 'MRP') {
                        this.mrp = parseFloat(row.value);
                        return;
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictNormativeInds', error.toString());
            }
        },

        async loadDictObl() {
            try {
                const response = await fetch('/api-py/dict_kato/06/');
                const items = await response.json();

                for (const row of items) {
                    if (row.par_id !== null) {
                        const el = {};
                        this.$set(el, 'value', row);
                        this.$set(el, 'text', row.name_ru);
                        this.dictObl.push(el);
                    }
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictObl', error.toString());
            }
        },

        async loadDictPost() {
            try {
                const response = await fetch('/api-py/dictionary/post_groups/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    this.dictPost.push(el);
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDictPost', error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        number(value) {
            return (isNaN(parseFloat(value)) ? 0 : parseFloat(value));
        },

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (!((row.post_group === null) || (row.local_category === null) || (row.kato === null))) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    if (row.post_group.hasOwnProperty('code') && row.local_category.hasOwnProperty('code') && row.kato.hasOwnProperty('code')) {
                        this.$set(item, 'post_group', row.post_group.code);
                        this.$set(item, 'local_category', row.local_category.code);
                        this.$set(item, 'kato', row.kato.code);
                    }
                    else {
                        this.$set(item, 'post_group', row.post_group);
                        this.$set(item, 'local_category', row.local_category);
                        this.$set(item, 'kato', row.kato);
                    }
                    this.$set(item, 'rent_norm', parseFloat(row.rent_norm));
                    this.$set(item, 'daily_avg', parseInt(row.daily_avg));
                    this.$set(item, 'rent_avg', parseInt(row.rent_avg));
                    this.$set(item, 'people_num', parseInt(row.people_num));
                    this.$set(item, 'cost_avg', parseFloat(row.cost_avg));
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'total', row.total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0 && this.variantAttribute) {
                this.save(values, error);
            } else {
                this.makeToast('warning', 'Сообщение', 'Данные не сохранены');
            }
        }, // подготовка к сохранению

        async save(values, error) {
            this.isLoad = true;
            try {
                const totalToSave = this.totalCalculation(this.budgetForm, 'total');
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch('/api-py/save-brform' + this.form.code + '/' +
                    JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    // await this.saveTotal();
                    if (this.files.length === 0) {
                        this.makeToast('danger', 'Предупреждение', 'Внимание! Отсутствуют необходимые документы. Пожалуйста, прикрепите недостающие файлы.');
                    }
                    this.makeToast('success', 'Сообщение', 'Данные сохранены');
                    await this.loadDictNormativeInds();
                    await this.loadDatas();
                } else {
                    await this.loadDictNormativeInds();
                    await this.loadDatas();
                    throw 'Ошибка сохранения данных. Возможно не все обязательные поля заполнены';
                }
            } catch (e) {
                this.makeToast('danger', 'Предупреждение', e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных

        async saveTotal() {
            this.$set(this.header, 'value', this.total);
            try {
                await fetch('/api-py/save-budget-request-total/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(this.header)
                });
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка сохранения итога');
            }
        } // сохранение итога
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        }
    }
};
</script>
<style scoped>
    .filter-actions-flex {
        display: flex;
    }
</style>